export const authenticateUser = (token) => {
    localStorage.setItem('token', token)
}

export const setTmpToken = (token) => {
    localStorage.setItem('tmpToken', token)
}

export const getTmpToken = () => {
    return localStorage.getItem('tmpToken')
}

export const setUserId = (userId) => {
    localStorage.setItem('userId', userId)
}

export const getUserId = () => {
    return localStorage.getItem('userId')
}

export const dataUser = (name) => {
    localStorage.setItem('user', JSON.stringify(`${name}`))
}

export const isUserAuthenticated = () => {
    return localStorage.getItem('token') !== null
}

export const deauthenticateUser= () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
}

export const getToken = () => {
    return localStorage.getItem('token')
}

export const getUser = () => {
    return localStorage.getItem('user').replace(/['"]+/g, '') 
}

export const setAdmin = (boolean) => {
    localStorage.setItem('isAdmin', JSON.stringify(boolean))
}

export const isAdmin = () => {
    return localStorage.getItem('isAdmin') !== null
}
