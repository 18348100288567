import React from 'react';
import ChangePassword from '../components/login/changePassword'

const ChangePasswordPage = ({history}) => {
    return ( 
        <>
            <ChangePassword history={history}/>
        </>
     );
}
 
export default ChangePasswordPage;