import React, {useContext} from 'react';

import { IconButton, Grid, Typography, Menu, MenuItem, Fade, Link} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import UserService from '../services/UserService';
import { DataContext } from '../context/dataContext';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
    name: {
        paddingLeft: '10px',
    },
}));

const Account = () => {
    const classes = useStyles();
    const { anchorEl, setAnchorEl } = useContext(DataContext);
    const open = Boolean(anchorEl);
    const User = new UserService();

    const handleLogout = () => {
        User.deauthenticateUser();
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    
    const handleClose = () => {
        setAnchorEl(null);
    }

    return ( 
        <>
        <Grid container justify='flex-end' alignItems='center'  style={{marginBottom: '1vh'}}>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item>
                        <IconButton style={{color: "#eee"}} size='medium' onClick={handleClick}>
                            <AccountCircleIcon size='medium' />
                            <Typography className={classes.name} variant="body1" component="p" color='inherit'>
                                {User.getUser() ? User.getUser().replace(/['"]+/g, '') : null}
                            </Typography>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <div className={classes.root}>
                    <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <Link href='/#/changePassword' underline='none'>
                            <MenuItem>Cambiar contraseña</MenuItem>
                        </Link>
                        <Link href='/' underline='none'>
                            <MenuItem onClick={handleLogout}>Salir</MenuItem>
                        </Link>
                    </Menu>
                </div>
            </Grid>
        </Grid>
        </>
     );
}
 
export default Account;