import React from 'react';
import { Grid, Container, Link } from '@material-ui/core'

const Logos = () => {
    return ( 
        <Container style={{marginTop: '1vh', marginBottom: '3vh'}}>
            <Link href='/' underline='none'>
                <Grid container spacing={2} style={{height: '10vh', width: '100%'}} alignItems='center' justify='center'>
                    <img src='/imgs/logo.png' alt='Marfrig' style={{width: '17%', maxHeight: '100%', borderRadius: 10, backgroundColor: '#00548b', padding: 5,  margin: '0.2%' }}/>
                </Grid>
            </Link>
        </Container>
     );
}
 
export default Logos;