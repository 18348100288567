import axios from 'axios'
import { axiosApiInstance } from './instance';
import { authenticateUser, dataUser, setTmpToken, getToken, setUserId, getUserId, isAdmin as isAdminAuthenticatedAuth, isUserAuthenticated as isUserAuthenticatedAuth, getUser as getUserAuthenticatedAuth,  deauthenticateUser as deauthenticateUserAuthenticatedAuth } from './auth/auth';

export default class Users {
    async getPallets() {
        try {
            const response = await axiosApiInstance.get(`${process.env.REACT_APP_SRV_BASE_URL}/pallets/${getUserId()}`);
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    async login(username, password) {
        let result = {
            status: true,
            errorCode: 0
        };
        try {
            var data = JSON.stringify({"username": username,"password" : password});
            const response = await axios.post(`${process.env.REACT_APP_SRV_BASE_URL}/login`, data,
                {headers: {'Content-Type': 'application/json'}})
                .catch(err => {
                    if (err.response.data.hasOwnProperty('errorCode') && err.response.data.errorCode === process.env.REACT_APP_PWD_REQUIRED_CODE) {
                        result.errorCode = process.env.REACT_APP_PWD_REQUIRED_CODE;
                        console.log(err.response.data.token);
                        setTmpToken(err.response.data.token);
                    }
                    throw err;
                });
            authenticateUser(response.data.user.token);
            dataUser(response.data.user.name);
            setUserId(response.data.user.user_id);
        } catch (error) {
            console.log(error);
            result.status = false;
        }
        return result;
    }

    async changePassword(payload) {
        console.log(payload);
        let result = {
            status: true
        };
        try {
            payload.token = getToken();
            var data = JSON.stringify(payload);
            const response = await axios.post(`${process.env.REACT_APP_SRV_BASE_URL}/updatePassword`, data,
                {headers: {'Content-Type': 'application/json'}})
            
            console.log(response);
            //authenticateUser(response.data.user.token);
            //dataUser(response.data.user.name);
            //setUserId(response.data.user.user_id);
        } catch (error) {
            console.log(error);
            result.status = false;
        }
        return result;
    }

    async getUserInfoById(id){
        try {
            const response = await axiosApiInstance.get(`/user/${id}`) 
            return response.data.user;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    loginValidate(user, setError) {
        if (user.username !== 'admin') {
            if ( user.username === '' ) {
                setError({severity : 'warning', message: "Debe ingresar su usuario"})
                return false;
            }
            if ( user.password === '' ) {
                setError({severity : 'warning', message: "Debe ingresar su contraseña"})
                return false;
            }
            if ( user.password.length < 7 ) {
                setError({severity : 'warning', message: "Contraseña incorrecta"})
                return false;
            }
        }
        return true;
    }

    changePasswordValidate(user, setError) {
        if ( user.password === '' || user.password_confirm === '' || user.current_password === '' ) {
            setError({severity : 'warning', message: "Debe ingresar su contraseña"})
            return false;
        }
        if ( user.password === user.current_password ) {
            setError({severity : 'warning', message: "La nueva contraseña debe ser diferente a la actual"})
            return false;
        }
        if ( user.password.length < 7 ) {
            setError({severity : 'warning', message: "Contraseña incorrecta"})
            return false;
        }
        if ( user.password !== user.password_confirm ) {
            setError({severity : 'warning', message: "La contraseña no coincide con la confirmación"})
            return false;
        }
        return true;
    }
    
    isUserAuthenticated() {
        return isUserAuthenticatedAuth();
    }

    isAdmin() {
        return isAdminAuthenticatedAuth();
    }

    getUser(){
        return getUserAuthenticatedAuth();
    }

    deauthenticateUser(){
        return deauthenticateUserAuthenticatedAuth();
    }
}