import React from 'react';
import Login from '../components/login/login'

const LoginPage = ({history}) => {
    return ( 
        <>
            <Login history={history}/>
        </>
     );
}
 
export default LoginPage;