import React, {useContext, useState} from 'react';
import { Button, CssBaseline, TextField, CircularProgress, Grid, Typography, Container} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DataContext } from '../../context/dataContext';
import UserService from '../../services/UserService';
import Message from '../Message';
import Logos from '../Logos';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ChangePassword({history}) {
    const classes = useStyles();
    const User = new UserService();
    const {setError} = useContext(DataContext);
    const [password, setPassword] = useState({
        current_password: '',
        password: '',
        password_confirm: ''
    })
    const [loading, setloading] = useState(false);
    
    const handleSubmit = e => {
        setloading(true);
        e.preventDefault();
        const validate = User.changePasswordValidate(password, setError);
        if (validate) {
            const changePassword = async (password) => {
                const payload = {
                    ...password
                };
                const result = await User.changePassword(payload);
                if (result.status){
                    setError(false)
                    history.push('/');
                } else {
                    setError({severity : 'warning', message: "No se pudo cambiar su contraseña, intente nuevamente"})
                }
            }
            changePassword(password);
        }
        setloading(false);
    }

    const onChange = e => {
        setPassword({
            ...password,
            [e.target.name] : e.target.value
        })
    }
    
    return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <Logos />
            <Typography component="h1" variant="h5">Cambiar contraseña</Typography>
            <Message/>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="current_password"
                    label="Contraseña actual"
                    type="password"
                    id="current_password"
                    autoComplete="current-password"
                    onChange={onChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={onChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password_confirm"
                    label="Repetir Contraseña"
                    type="password"
                    id="password_confirm"
                    autoComplete="current-password"
                    onChange={onChange}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >Actualizar</Button>
            </form>
        </div>
        <Grid container justify='center' alignContent='center' style={{height: '10vh'}}>
            <Grid item>
                { loading && <CircularProgress /> }
            </Grid>
        </Grid>
    </Container>
    );
}
