import React, {useEffect, useContext} from 'react'; 

import { Container, AppBar, Toolbar, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';

import { DataContext } from '../context/dataContext'
import UserService from '../services/UserService';

import Table from '../components/CustomTable'
import Account from '../components/Account'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const Index = ({history}) => {
    const { auth, pallets, setPallets, renderPallets, setRenderPallets } = useContext(DataContext);
    const classes = useStyles();
    const User = new UserService();
    
    useEffect(() => {
        const getPallets = async () => {
            if (!pallets) {
                const response = await User.getPallets();
                if (response !== undefined) {
                    let pallets = response.data.pallets.map(pallet => {
                        delete pallet.user_id;
                        delete pallet.__v;
                        return pallet;
                    });
                    setPallets(pallets);
                    setRenderPallets(pallets);
                } else {
                    User.deauthenticateUser();
                    history.push('/login');
                }
            }
        }
        getPallets();
    // eslint-disable-next-line
    }, [auth])

    return ( 
        <div style={{marginBottom: '5vh'}}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <img src='/imgs/logo.png' alt='Marfrig' style={{width: '45px', maxHeight: '100%', borderRadius: 10, backgroundColor: '#eee', padding: 5,  margin: '0.2%' }}/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                         Marfrig
                    </Typography>
                    <Account />
                </Toolbar>
            </AppBar>
            <Container style={{marginTop: '5vh'}}>
                {renderPallets ? <Table header='Pallets' data={renderPallets} history={history} />:
                null}
            </Container>
        </div>
     );
}
 
export default Index;