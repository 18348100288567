import React, {createContext, useState} from 'react';

export const DataContext = createContext();

const DataProvider = ({children}) => {
    const [darkmode, setdarkMode] = React.useState(false);
    const [auth, setauth] = useState(undefined);
    const [error, setError] = useState(false);
    const [pallets, setPallets] = useState(false);
    const [renderPallets, setRenderPallets] = useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    return(
        <DataContext.Provider
            value={{
                auth,
                error,
                darkmode,
                pallets,
                renderPallets,
                page,
                rowsPerPage,
                anchorEl,
                mobileMoreAnchorEl,
                open,
                setOpen,
                setPage,
                setRowsPerPage,
                setauth,
                setError,
                setdarkMode,
                setPallets,
                setRenderPallets,
                setAnchorEl,
                setMobileMoreAnchorEl,
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export default DataProvider;