const columns = [
  {
  id: "agreement_id",
  displayName: "Contrato"
  }, 
  {
  id: 'short_description',
  displayName: 'Descripción'
  }, 
  {
  id: 'reception_date',
  displayName: 'Fecha de recepción'
  }, 
  {
  id: 'reception_document',
  displayName: 'Documento'
  }, 
  {
  id: 'short_description',
  displayName: 'Descripción'
  }, 
  {
  id: 'pallet_number',
  displayName: 'Número de pallet'
  }, 
  {
  id: 'product',
  displayName: 'Producto'
  }, 
  {
  id: 'pallet_items_qty',
  displayName: 'Cantidad de bultos'
  }, 
  {
  id: 'reception_date',
  displayName: 'Fecha de ingreso'
  }, 
  {
  id: 'production_date',
  displayName: 'Fecha de producción'
  }, 
  {
  id: 'expire_date',
  displayName: 'Fecha de vencimiento'
  }, 
  {
  id: 'measure',
  displayName: 'Calibre'
  }, 
  {
  id: 'process',
  displayName: 'Proceso'
  }, 
  {
  id: 'sscc',
  displayName: 'SSCC'
  }, 
  {
  id: 'pallet_state',
  displayName: 'Estado del pallet'
  }, 
  {
  id: 'weight',
  displayName: 'Peso bruto'
  }, 
  {
  id: 'weight_net',
  displayName: 'Peso neto'
  }, 
  {
  id: 'tare',
  displayName: 'Tara'
  }, 
  {
  id: 'certificate',
  displayName: 'Certificado'
  }, 
  {
  id: 'additionals',
  displayName: 'Información varia'
  }
];

export default columns;