import React, { useContext } from 'react';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Grid, TablePagination, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { DataContext } from '../context/dataContext';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import SearchBar from '../components/SearchBar'

import CsvDownloader from 'react-csv-downloader';
import columnsForCSV from '../assets/columsForCSV';

const useRowStyles = makeStyles((theme) => ({
    table: {
        borderRadius: '1vh',
        border: `0.0001vh solid ${theme.palette.common.black}`
    },
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}))

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        textAlign: 'center'
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell component="th" align="center" scope="row">{row.agreement_id}</TableCell>
                <TableCell align="center">{row.short_description}</TableCell>
                <TableCell align="center">{row.admission_date}</TableCell>
                <TableCell align="center">{row.reception_document}</TableCell>
                <TableCell align="center">{row.pallet_number}</TableCell>
                <TableCell align="center">{row.product}</TableCell>
                <TableCell align="center">{row.pallet_items_qty}</TableCell>
                <TableCell align="center">{row.reception_date}</TableCell>
                <TableCell align="center">{row.production_date}</TableCell>
                <TableCell align="center">{row.expire_date}</TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 20, paddingTop: 0 }} colSpan={11}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Calibre</TableCell>
                                        <TableCell align="center">Proceso</TableCell>
                                        <TableCell align="center">SSCC</TableCell>
                                        <TableCell align="center">Estado del pallet</TableCell>
                                        <TableCell align="center">Peso bruto</TableCell>
                                        <TableCell align="center">Peso neto</TableCell>
                                        <TableCell align="center">Tara</TableCell>
                                        <TableCell align="center">Certificado</TableCell>
                                        <TableCell align="center">Información varia</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" component="th" scope="row">{row.measure}</TableCell>
                                        <TableCell align="center">{row.process}</TableCell>
                                        <TableCell align="center">{row.sscc}</TableCell>
                                        <TableCell align="center">{row.pallet_state}</TableCell>
                                        <TableCell align="center">{row.weight}</TableCell>
                                        <TableCell align="center">{row.weight_net}</TableCell>
                                        <TableCell align="center">{row.tare}</TableCell>
                                        <TableCell align="center">{row.certificate}</TableCell>
                                        <TableCell align="center">{row.additionals}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const CustomTable = ({ header, data }) => {
    const classes = useRowStyles();
    const { page, rowsPerPage, setPage, setRowsPerPage } = useContext(DataContext);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        console.log(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        console.log(event.target.value);
    };


    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={8} lg={4}>
                    <Typography variant="h5" component="h5" gutterBottom >{header}</Typography>
                </Grid>
            </Grid>
            <Grid container justify='space-between' alignItems='center' style={{marginBottom: 5}}>
                <Grid item xs={12} sm={8} lg={4}>
                    <SearchBar />
                </Grid>
                <Grid item xs={12} sm={8} lg={4}>
                    <CsvDownloader 
                        filename="Marfrig report"
                        extension=".csv"
                        separator=";"
                        columns={columnsForCSV} 
                        datas={data}>
                        <Button variant="contained" color="primary" startIcon={<SaveIcon />} fullWidth>Descargar</Button>
                    </CsvDownloader>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <TableContainer className={classes.table} component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell key="1"># Contrato</StyledTableCell>
                                <StyledTableCell key="2">Descripción</StyledTableCell>
                                <StyledTableCell key="3">Fecha de recepción</StyledTableCell>
                                <StyledTableCell key="4">Documento</StyledTableCell>
                                <StyledTableCell key="5">Número de pallet</StyledTableCell>
                                <StyledTableCell key="6">Producto</StyledTableCell>
                                <StyledTableCell key="7">Cantidad de bultos</StyledTableCell>
                                <StyledTableCell key="8">Fecha de ingreso</StyledTableCell>
                                <StyledTableCell key="9">Fecha de producción</StyledTableCell>
                                <StyledTableCell key="10">Fecha de vencimiento</StyledTableCell>
                                <StyledTableCell key="11">Ver más</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <Row key={row._id} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

export default CustomTable;